"use client";

import useVisitor from "@/lib/visitor/useVisitor";
import { useEffect } from "react";

const formatPhoneNumberE164 = (phoneNumber: string) => {
  if (phoneNumber.startsWith("91") && phoneNumber.length === 12) {
    return `+${phoneNumber}`;
  }
  if (phoneNumber.length === 10) {
    return `+91${phoneNumber}`;
  }
  if (phoneNumber.startsWith("+91") && phoneNumber.length === 13) {
    return phoneNumber;
  }
  throw new Error("Invalid phone number format");
};

interface EnhancedConversionUser {
  email?: string | null;
  phone?: string | null;
  firstName?: string | null;
  surName?: string | null;
}

function GoogleEnhancedConversions() {
  const { visitor, isLoading } = useVisitor();

  useEffect(() => {
    const setEnhancedConversionDataInWindow = async (
      user: EnhancedConversionUser
    ) => {
      const phone = user.phone ? formatPhoneNumberE164(user.phone) : undefined;
      window.googleEnhancedConversionUserData = {
        email: user.email,
        phone,
      };
      window?.dataLayer?.push("set", "user_data", {
        email: user.email,
        phone_number: phone,
      });
    };

    const unsetEnhancedConversionDataInWindow = () => {
      window.googleEnhancedConversionUserData = {};
      window?.dataLayer?.push("set", "user_data", {
        email: undefined,
        phone_number: undefined,
      });
    };

    if (!isLoading) {
      if (visitor) {
        setEnhancedConversionDataInWindow({
          email: visitor.email,
          phone: visitor.phone,
          firstName: visitor.name,
        });
      } else {
        unsetEnhancedConversionDataInWindow();
      }
    }
  }, [visitor, isLoading]);

  return null;
}

export default GoogleEnhancedConversions;
