"use client";

import AuthenticateModal from "@/components/authenticate";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import dynamic from "next/dynamic";
import React, { Suspense } from "react";
import { Toaster } from "sonner";
import { SWRConfig } from "swr";
import GoogleEnhancedConversions from "./GoogleEnhancedConversions";
import { PHProvider } from "./posthog";

type FetcherParams = string | [string, string];

export const fetcher = (
  params: FetcherParams,
  ...args: RequestInit[]
): Promise<unknown> => {
  let url: string;
  let queryString: string | undefined;
  if (typeof params === "string") {
    url = params;
  } else {
    url = params[0];
    queryString = params[1] || undefined;
  }

  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${url}${queryString ? "?" + queryString : ""}`,
      ...args
    ).catch((error) => {
      reject({
        message: error?.message,
        error: error,
      });
      return null;
    });
    if (!response) {
      return;
    }
    if (response.ok) {
      const data = await response.json();
      resolve(data);
      return;
    }
    const errorResponse = await response.text();
    reject(errorResponse);
  });
};

const PostHogPageView = dynamic(() => import("./posthog"), {
  ssr: false,
});

function Providers({ children }: { children: React.ReactNode }) {
  return (
    <Suspense>
      <SWRConfig
        value={{
          fetcher: fetcher,
        }}
      >
        <PHProvider>
          {children}
          <PostHogPageView />
        </PHProvider>

        <AuthenticateModal />
      </SWRConfig>
      <ProgressBar color="#000" />
      <Toaster richColors />
      <GoogleEnhancedConversions />
    </Suspense>
  );
}

export default Providers;
